import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import classnames from 'classnames';

import theme from 'src/styles/components/TableTabs.module.scss';

const TableTab = ({
    description,
    selectedTab,
    setTab,
    value
}) => {
    const handleSetTab = useCallback(() => {
        setTab(value);
    });

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            setTab(value);
        }
    });

    return (
        <div
          onClick={handleSetTab}
          onKeyDown={handleKeyDown}
          role="button"
          className={classnames(theme.tab, { [theme.selected]: selectedTab === value })}
          tabIndex={0}
        >
            {description}
        </div>
    );
};

TableTab.propTypes = {
    selectedTab: PropTypes.string.isRequired,
    setTab: PropTypes.func.isRequired,
    description: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default TableTab;
