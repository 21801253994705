import React, { useContext } from 'react';
import DiContext from '@aveneo/frontmoon-di/lib';

import theme from 'src/styles/components/TableSearch.module.scss';
import { useIntl } from 'react-intl';

const TableSearch = () => {
    const intl = useIntl();

    const context = useContext(DiContext);
    const { InputForm } = context.container;

    return (
        <div className={theme.container}>
            <InputForm
              className={theme.searchInput}
              name="searchText"
              placeholder={intl.formatMessage({ id: 'App.search' })}
              intl={intl}
              required
              icon="icon-search"
              theme={theme}
            />
        </div>
    );
};

export default TableSearch;
