import PropTypes from 'prop-types';
import { useEffect } from 'react';

const Tables = ({
    connectTableSocket,
    disconnectTableSocket,
    token
}) => {
    useEffect(async () => {
        if (token) {
            await connectTableSocket();
        }

        return () => {
            disconnectTableSocket();
        };
    }, [token]);

    return null;
};

Tables.propTypes = {
    token: PropTypes.string.isRequired,
    disconnectTableSocket: PropTypes.func.isRequired,
    connectTableSocket: PropTypes.func.isRequired
};

export default Tables;
