import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'next/link';
import { SECTION } from 'src/modules/Settings';
import useRestaurantSlugPrefix from 'src/hooks/useRestaurantSlugPrefix';

import FooterLogo from 'src/components/FooterLogo';
import RestaurantLogo from 'src/components/RestaurantLogo';

import theme from 'src/styles/components/Footer.module.scss';

const Footer = ({
    className,
    actionCount,
    activeSection,
    isMenuEnabled
}) => {
    const prefix = useRestaurantSlugPrefix();

    return (
        <div className={classnames(className, theme.footer)}>
            {isMenuEnabled && (
                <div className={theme.settings}>
                    <Link href={`${prefix}/tables`} shallow>
                        <div
                          className={classnames(theme.mode, { [theme.active]: activeSection === SECTION.TABLES })}
                          role="button"
                          tabIndex={0}
                        >
                            <span className="icon-food" />
                            {Boolean(actionCount) && <span className={theme.badge}>{actionCount}</span>}
                        </div>
                    </Link>
                    <Link href={`${prefix}/settings/tables`} shallow>
                        <div
                          className={classnames(theme.mode, { [theme.active]: activeSection === SECTION.SETTINGS })}
                          role="button"
                          tabIndex={0}
                        >
                            <span className="icon-settings" />
                        </div>
                    </Link>
                </div>
            )}
            <div className={theme.appLogo}>
                <FooterLogo />
            </div>
            <div className={theme.restaurantLogo}>
                <RestaurantLogo />
            </div>
        </div>
    );
};

Footer.propTypes = {
    actionCount: PropTypes.number.isRequired,
    activeSection: PropTypes.string,
    isMenuEnabled: PropTypes.bool,
    className: PropTypes.string
};

Footer.defaultProps = {
    activeSection: 'TABLES',
    isMenuEnabled: true
};

export default Footer;
