import { connect } from 'react-redux';
import { selectForm } from '@aveneo/frontmoon-form/lib/modules/Form';
import {
} from 'src/modules/Tables';

import { FORM_ID } from './constants';

const mapStateToProps = (state) => {
    const formData = selectForm(state, FORM_ID, {});

    return ({
        formData
    });
};

export default connect(mapStateToProps);
