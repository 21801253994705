import { connect } from 'react-redux';
import {
    selectTablesNotifcation
} from 'src/modules/Tables';

const mapStateToProps = (state) => ({
    notification: selectTablesNotifcation(state)
});

export default connect(mapStateToProps);
