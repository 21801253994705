import { connect } from 'react-redux';

import { emitMessage, dismissFirstMessage } from 'src/modules/Modal';

const mapDispatchToProps = {
    emitMessage,
    dismissFirstMessage
};

export default connect(null, mapDispatchToProps);
