import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const SoundNotificationManager = ({
    notification
}) => {
    const [audio, setAudio] = useState(null);

    useEffect(() => {
        setAudio(new Audio('/notifications/action.mp3'));

        return () => {
            setAudio(null);
        };
    }, []);

    useEffect(() => {
        if (notification && audio && (
            notification.type === 'waiterCalled'
            || notification.type === 'billClosed'
            || notification.type === 'newOrder'
            || notification.type === 'repeat')) {
            audio.play();
        }
    }, [notification, audio]);

    return null;
};

SoundNotificationManager.propTypes = {
    notification: PropTypes.shape({
        type: PropTypes.string.isRequired
    })
};

export default SoundNotificationManager;
