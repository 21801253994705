import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import Button from 'src/components/Button';
import { MESSAGE_TYPE } from 'src/modules/Modal';

import theme from 'src/styles/components/LogoutButton.module.scss';

const LogoutButton = ({ emitMessage, dismissFirstMessage }) => {
    const router = useRouter();
    const intl = useIntl();

    const handleLogout = useCallback(() => {
        emitMessage({
            title: intl.formatMessage({ id: 'App.logout' }),
            message: intl.formatMessage({ id: 'App.logoutInformation' }),
            actions: (
                <>
                    <Button
                      className={theme.modalButton}
                      label={intl.formatMessage({ id: 'App.no' })}
                      onClick={() => { dismissFirstMessage(); }}
                      raised
                      secondary
                    />
                    <Button
                      label={intl.formatMessage({ id: 'App.yes' })}
                      onClick={() => {
                         router.push('/logout');
                         dismissFirstMessage();
                      }}
                      accent
                      flat
                    />
                </>
            ),
            isEscKeyDownEnabled: true,
            contentClassName: theme.modalContent,
            isDismissButtonEnabled: true,
            type: MESSAGE_TYPE.SMALL
        });
    });

    return (
        <Button
          className={theme.button}
          icon="icon-logout"
          onClick={handleLogout}
          floating
          flat
          mini
          primary
          transparent
        />
    );
};

LogoutButton.propTypes = {
    emitMessage: PropTypes.func.isRequired,
    dismissFirstMessage: PropTypes.func.isRequired
};

export default LogoutButton;
