import { connect } from 'react-redux';
import {
    connectTableSocket,
    disconnectTableSocket
} from 'src/modules/Tables';

import {
    selectUserToken
} from 'src/modules/User';

const mapDispatchToProps = {
    connectTableSocket,
    disconnectTableSocket
};

const mapStateToProps = (state) => ({
    token: selectUserToken(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
