import { connect } from 'react-redux';
import {
    selectTabs
} from 'src/modules/Tables';

const mapStateToProps = (state) => ({
    tabs: selectTabs(state)
});

export default connect(mapStateToProps);
