import { connect } from 'react-redux';
import {
    selectUserRestaurantStyles
} from 'src/modules/User';

const mapStateToProps = (state) => ({
    ...selectUserRestaurantStyles(state)
});

export default connect(mapStateToProps, null);
