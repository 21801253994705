import { connect } from 'react-redux';
import {
    selectActiveTab,
    setTab
} from 'src/modules/Tables';

const mapDispatchToProps = {
    setTab
};

const mapStateToProps = (state) => ({
    selectedTab: selectActiveTab(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
