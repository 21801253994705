import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { DEFAULT_TAB } from 'src/modules/Tables/constants';

import theme from 'src/styles/components/TableTabs.module.scss';

import Tab from '../TableTab';

const TableTabs = ({
    tabs
}) => {
    const intl = useIntl();

    return (
        <div className={theme.list}>
            <Tab value={DEFAULT_TAB} description={intl.formatMessage({ id: 'App.all' })} />
            {(tabs) && tabs.map((tab) => (
                <Tab key={tab} value={tab} description={tab} />
            ))}
        </div>
    );
};

TableTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string)
};

export default TableTabs;
