import compose from '@aveneo/frontmoon-utils/lib/hocs/compose';
import { reduxForm } from 'redux-form';
import { FORM_ID } from './constants';
import TableSearch from './TableSearch';
import connectContainer from './TableSearchContainer';

export default compose(
    reduxForm({ form: FORM_ID }),
    connectContainer
)(TableSearch);
