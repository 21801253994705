import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Notifications from 'src/components/Notifications';
import Modal from 'src/components/Modal';
import HeaderComponent from 'src/components/Header';
import Footer from 'src/components/Footer';
import TablesSocketConnectionManager from 'src/components/TablesSocketConnectionManager';
import SoundNotificationManager from 'src/components/SoundNotificationManager';

import theme from 'src/styles/layouts/PanelLayout.module.scss';

const PanelLayout = ({
    children,
    activeSection,
    isDarkMode,
    isMenuEnabled,
    isHeaderVisible,
    Header,
    styles
}) => (
    <div className={(classnames({ 'dark': isDarkMode }))}>
        <Notifications />
        <Modal isDarkMode={isDarkMode} />
        <TablesSocketConnectionManager />
        <SoundNotificationManager />
        <div className={classnames(theme.container)}>
            <div className={theme.background} style={{ backgroundImage: styles.background }} />
            {isHeaderVisible && <Header className={theme.header} />}
            <div className={theme.content}>
                {children}
            </div>
            <Footer className={theme.footer} activeSection={activeSection} isMenuEnabled={isMenuEnabled} />
        </div>
    </div>
);

PanelLayout.propTypes = {
    children: PropTypes.node,
    activeSection: PropTypes.string,
    isDarkMode: PropTypes.bool,
    isMenuEnabled: PropTypes.bool,
    isHeaderVisible: PropTypes.bool,
    Header: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),
    styles: PropTypes.object
};

PanelLayout.defaultProps = {
    isDarkMode: true,
    isHeaderVisible: true,
    isMenuEnabled: true,
    Header: HeaderComponent,
    styles: {}
};

export default PanelLayout;
