import classNames from 'classnames';
import PropTypes from 'prop-types';

import theme from 'src/styles/components/RestaurantLogo.module.scss';

const RestaurantLogo = ({
    logo
}) => (
    <div className={theme.container}>
        <div className={theme.imageBox}>
            <img
              className={classNames(theme.logo, { [theme.noBackground]: !logo })}
              src={logo || '/logo.svg'}
              alt="restaurant logo"
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.onerror = null; // prevents looping
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = '/logo.svg';
              }}
            />
        </div>
    </div>
);

RestaurantLogo.propTypes = {
    logo: PropTypes.string
};

RestaurantLogo.defaultProps = {
    logo: null
};

export default RestaurantLogo;
