import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TableTabs from 'src/components/TableTabs';
import TableSearch from 'src/components/TableSearch';
import TableFilter from 'src/components/TableFilter';
import LogoutButton from 'src/components/LogoutButton';

import theme from 'src/styles/components/Header.module.scss';

const Header = ({ className }) => (
    <div className={classnames(className, theme.tabs)}>
        <TableTabs />
        <div className={theme.spacing} />
        <TableSearch />
        <TableFilter />
        <LogoutButton />
    </div>
);

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
