import { connect } from 'react-redux';
import {
    selectActionCount
} from 'src/modules/Tables';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
    actionCount: selectActionCount(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
