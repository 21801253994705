import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import classnames from 'classnames';

import theme from 'src/styles/components/TableFilter.module.scss';

const TableFilter = ({
    filterStatus,
    setTableFilter
}) => {
    const changeFilterState = useCallback(() => {
        setTableFilter(!filterStatus);
    });

    return (
        <div className={theme.container} onClick={changeFilterState} role="button" tabIndex={0}>
            <span className={classnames('icon-attention', theme.searchIcon, { [theme.active]: filterStatus })} />
            {filterStatus}
        </div>
    );
};

TableFilter.propTypes = {
    filterStatus: PropTypes.bool.isRequired,
    setTableFilter: PropTypes.func.isRequired
};

export default TableFilter;
